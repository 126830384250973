import { Head } from '@inertiajs/react'
import SummaryCard from '@/components/ui/giveaway/summary-card/summary-card'
import AuthenticatedLayout from '@/Layouts/AuthenticatedLayout'
import { PageProps } from '@/types'
import {
    Card,
    CardHeader,
    CardBody,
    CardFooter,
    Divider,
    Link,
    Image,
    Chip
} from '@nextui-org/react'
import { usePage } from '@inertiajs/react'
import AppHead from '@/Layouts/app-head'

import BannerWithBg from '@/components/ui/hero-banner/banner-with-bg/banner-with-bg'
import Banner from '@/components/ui/hero-banner/banner/banner'
import SplitWithContentImage from '@/components/ui/hero-banner/split-with-content-image/split-with-content-image'
import Guest from '@/Layouts/GuestLayout'
import Authenticated from '@/Layouts/AuthenticatedLayout'
import Cta from '@/components/ui/cta/cta'

export default function Welcome ({
    auth,
    laravelVersion,
    phpVersion
}: PageProps<{ laravelVersion: string; phpVersion: string }>) {
    const handleImageError = () => {
        document
            .getElementById('screenshot-container')
            ?.classList.add('!hidden')
        document.getElementById('docs-card')?.classList.add('!row-span-1')
        document.getElementById('docs-card-content')?.classList.add('!flex-row')
        document.getElementById('background')?.classList.add('!hidden')
    }
    const pageProps = usePage()?.props

    return (
        <>
            <AppHead title='Welcome' />
            {pageProps?.errors?.message && (
                <Chip color='danger'>{pageProps?.errors?.message}</Chip>
            )}
            <div className='min-h-screen w-full'>
                <BannerWithBg
                    entryId='123'
                    body=''
                    headline={['Solid Base', 'Solid Base']}
                    img={{
                        alt: '',
                        url: 'https://images.pexels.com/photos/113850/pexels-photo-113850.jpeg',
                        radius: 'none',
                        enableZoom: true,
                        enableBlur: true
                    }}
                />
                <div className='spacing-component-padding '></div>
                <Cta
                    backgroundColor='Primary'
                    title='We Love to Write Code'
                    entryId='123'
                    body={`We are dedicated to bringing your ideas to life through innovative and precise coding.`}
                    images={[
                        {
                            alt: '',
                            url: 'https://images.pexels.com/photos/1181263/pexels-photo-1181263.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1'
                        }
                    ]}
                    actions={[
                        {
                            label: 'Contact Us',
                            url: '/contact-us',
                            color: 'secondary'
                        }
                    ]}
                />
            </div>
        </>
    )
}
